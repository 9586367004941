<template>
  <v-card flat max-width="550">
    <v-row wrap no-gutters>
      <v-col>
        <v-alert
          v-if="error"
          v-model="error"
          dense
          outlined
          border="left"
          type="error"
          class="mb-0"
          dismissable
        >
          {{ error.message }}
        </v-alert>
        <v-list>
          <v-list-item v-if="editing !== 'name'">
            <v-list-item-content>
              <v-list-item-title>
                Layout Name:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ layout.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="!editing"
                icon
                @click="editing = 'name'"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <validation-provider
            v-else
            v-slot="{ errors }"
            rules="required|min:5"
            name="Name"
          >
            <v-text-field
              v-model="name"
              label="Layout Name"
              type="text"
              outlined
              class="mr-5"
              :error-messages="errors"
              required
              append-outer-icon="mdi-check"
              :loading="saving"
              @click:append-outer="saveName"
            />
          </validation-provider>
          <v-list-item v-if="editing !== 'resolution'">
            <v-list-item-content>
              <v-list-item-title>
                Layout Resolution:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ layout.resolution | formatResolution }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="!editing"
                icon
                @click="editing = 'resolution'"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-row v-else align="center" class="mr-1">
            <v-col class="grow">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Width"
              >
                <v-text-field
                  v-model="resolution.width"
                  label="Width"
                  type="number"
                  outlined
                  prepend-icon="mdi-arrow-expand-horizontal"
                  :error-messages="errors"
                  required
                  hide-details
                />
              </validation-provider>
            </v-col>
            <v-col class="grow">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Height"
              >
                <v-text-field
                  v-model="resolution.height"
                  label="Height"
                  type="number"
                  outlined
                  prepend-icon="mdi-arrow-expand-vertical"
                  :error-messages="errors"
                  required
                  hide-details
                />
              </validation-provider>
            </v-col>
            <v-col class="shrink">
              <v-btn
                icon
                :loading="saving"
                @click="saveResolution"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Minimum plan required:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ layout.minPlan | displayPlan }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="importableLayouts.length">
            <v-list-item-content>
              <v-list-item-title>
                Import Layout
              </v-list-item-title>
              <v-list-item-subtitle>
                Copy all of the configurations from another layout.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                text
                icon
                @click="importFrom = true"
              >
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Delete Layout
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                color="error"
                :loading="deleting"
                @click="deleteLayout"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-dialog
            v-model="importFrom"
            max-width="360"
            :persistent="!!duplicatingLayout"
          >
            <v-card light>
              <v-card-title style="position: relative;">
                Import Data From Layout
                <v-btn
                  fab
                  absolute
                  right
                  text
                  class="mr-n3"
                  @click="importFrom = false"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pt-6">
                <v-list>
                  <v-list-item
                    v-for="item in importableLayouts"
                    :key="item.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn
                        :loading="duplicatingLayout === item.id"
                        @click="duplicateLayout(item.id)"
                      >
                        <v-icon
                          left
                          color="primary"
                        >
                          mdi-content-copy
                        </v-icon>
                        Copy
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
export default {
  data: () => ({
    error: null,
    editing: false,
    saving: false,
    deleting: false,
    name: '',
    resolution: {},
    importFrom: false,
    duplicatingLayout: null
  }),
  computed: {
    importableLayouts() {
      if (!this.layout.id) {
        return []
      }
      return this.$store.getters.layouts.filter(x => x.id !== this.layout.id)
    },
    ...mapState(['layoutId']),
    ...mapGetters(['layout'])
  },
  watch: {
    layout: {
      handler(layout) {
        this.name = layout.name
        this.resolution = JSON.parse(JSON.stringify(layout.resolution))
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async saveName() {
      this.saving = 'name'
      if (this.layout.name !== this.name) {
        await this.$db.collection('layouts').doc(this.layout.id).update({
          name: this.name
        })
      }
      this.saving = false
      this.editing = false
    },
    async saveResolution() {
      this.saving = 'resolution'
      if (this.layout.resolution !== this.resolution) {
        await this.$db.collection('layouts').doc(this.layout.id).update({
          resolution: this.resolution
        })
      }
      this.saving = false
      this.editing = false
    },
    async deleteLayout() {
      if (!confirm('Warning! This will delete all data together with the layout. Proceed?')) {
        return
      }
      this.deleting = true
      // delete all widgets, pages and plugins of layout
      const layoutWidgets = await db.collection('layout-widgets').where('layoutId', '==', this.layout.id).get()
      await Promise.all(layoutWidgets.docs.map(async(x) => {
        await db.collection('layout-widgets').doc(x.id).delete()
      }))
      const layoutPages = await db.collection('layout-pages').where('layoutId', '==', this.layout.id).get()
      await Promise.all(layoutPages.docs.map(async(x) => {
        await db.collection('layout-pages').doc(x.id).delete()
      }))
      const layoutPlugins = await db.collection('layout-plugins').where('layoutId', '==', this.layout.id).get()
      await Promise.all(layoutPlugins.docs.map(async(x) => {
        await db.collection('layout-plugins').doc(x.id).delete()
      }))
      await db.collection('layouts').doc(this.layout.id).delete()
      this.$router.push({ name: 'layouts' })
    },
    async duplicateLayout(id) {
      if (!confirm('Warning! This will delete all data on the current layout and replace it with data from the imported layout. Proceed?')) {
        return
      }
      this.duplicatingLayout = id
      const { theme, pages, plugins, widgets } = this.$store.getters.layoutById(id)
      await db.collection('layouts').doc(this.layout.id).update({ theme })
      // delete all widgets, pages and plugins of layout
      const layoutWidgets = await db.collection('layout-widgets').where('layoutId', '==', this.layout.id).get()
      await Promise.all(layoutWidgets.docs.map(async(x) => {
        await db.collection('layout-widgets').doc(x.id).delete()
      }))
      const layoutPages = await db.collection('layout-pages').where('layoutId', '==', this.layout.id).get()
      await Promise.all(layoutPages.docs.map(async(x) => {
        await db.collection('layout-pages').doc(x.id).delete()
      }))
      const layoutPlugins = await db.collection('layout-plugins').where('layoutId', '==', this.layout.id).get()
      await Promise.all(layoutPlugins.docs.map(async(x) => {
        await db.collection('layout-plugins').doc(x.id).delete()
      }))
      // copy components
      const newPageIds = await Promise.all(pages.map(async(page) => {
        const { pageId, name, options, userId, organizationId } = page
        const { id } = await db.collection('layout-pages').add({
          layoutId: this.layout.id,
          pageId,
          name,
          options,
          userId,
          organizationId
        })
        return {
          oldId: page.id,
          newId: id
        }
      }))
      await Promise.all(widgets.map(async(widget) => {
        const { widgetId, name, options, gridOptions, userId, organizationId } = widget
        let newOptionsJson = JSON.stringify(options)
        newPageIds.forEach((ids) => {
          if (newOptionsJson.includes(ids.oldId)) {
            newOptionsJson = newOptionsJson.replace(ids.oldId, ids.newId)
          }
        })
        await db.collection('layout-widgets').add({
          layoutId: this.layout.id,
          widgetId,
          name,
          gridOptions,
          options: JSON.parse(newOptionsJson),
          userId,
          organizationId
        })
      }))
      await Promise.all(plugins.map(async(plugin) => {
        const { pluginId, name, options, userId, organizationId } = plugin
        await db.collection('layout-plugins').add({
          layoutId: this.layout.id,
          pluginId,
          name,
          options,
          userId,
          organizationId
        })
      }))
      this.duplicatingLayout = null
      this.importFrom = false
    }
  }
}
</script>
